import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import api from '../../service/api.js';
import { formataData } from '../../util/maskdoc.js';
import styles from './param.module.css';
import './param.module.css';
import ModalGerarChave from './modalGerarChave/index.jsx';
import LoadingComponent from '../../components/loading-component/index.jsx';
import { UsuarioContext } from '../../context/userContext.jsx';
import ModalInfosParam from './modalInfos/index.jsx';
import ModalChaveParam from './modalChaveParam/index.jsx';
import ClienteRow from './linha-tabela.jsx';
import ListaParamPDF from '../../components/pdf-make/PDFListagemParam.jsx';
import Titulo from '../../components/titulo/index.jsx';

const initialState = {
  listaParam: [],
  listagem: [],
  modalOpen: false,
  modalInfoOpen: false,
  modalChaveParam: false,
  modalCliente: {},
  loading: false,
};

const filtrosInicial = {
  situacao: '',
  financeiro: '',
  tipoPesquisa: '0',
  pesquisa: '',
  disponibilidade: '',
  menorQue: '',
  dias: '',
};

function ListaParam() {
  const [state, setState] = useState(initialState);
  const [filtros, setFiltros] = useState(filtrosInicial);
  const [temListagem, setTemListagem] = useState(false);
  const { permissoesUsuario, isAdminUsuario } = useContext(UsuarioContext);

  const getListagem = () => {
    if (!temListagem) {
      setState((prevState) => ({ ...prevState, loading: true }));
      api
        .get('/v1/financeiro/situacao')
        .then((result) => {
          setState((prevState) => ({
            ...prevState,
            listaParam: result.data,
            listagem: result.data,
          }));
          setTemListagem(true);
        })
        .catch((err) => {
          console.error('Erro ao buscar dados:', err);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    } else {
      api
        .get('/v1/financeiro/situacao')
        .then((result) => {
          setState((prevState) => ({
            ...prevState,
            listaParam: result.data,
            listagem: atualizarListagem(result.data, prevState.listagem),
          }));
        })
        .catch((err) => {
          console.error('Erro ao buscar dados:', err);
        });
    }
  };

  function atualizarListagem(listagemApi, listagemAtual) {
    return listagemApi.filter((item) => listagemAtual.some((itemApi) => itemApi.cliente_id === item.cliente_id));
  }

  const compararDatas = (data) => {
    const dataAtual = new Date();
    const dataFornecidaObj = new Date(data);
    const diferencaEmMilissegundos = dataFornecidaObj - dataAtual;
    const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
    return diferencaEmDias;
  };

  const aplicarFiltro = () => {
    if (state.listaParam.length > 0) {
      const ano = new Date().getFullYear();
      const mes = new Date().getMonth();
      const dia = new Date().getDate();
      let registrosFiltrados = [...state.listaParam];

      if (filtros.situacao === '0') {
        registrosFiltrados = registrosFiltrados.filter((item) => item.dias_expirar <= 0);
      }
      if (filtros.situacao === '1') {
        registrosFiltrados = registrosFiltrados.filter((item) => item.dias_expirar > 0);
      }
      if (filtros.financeiro === '0') {
        registrosFiltrados = registrosFiltrados.filter((item) => item.dias < 0);
      }
      if (filtros.financeiro === '1') {
        registrosFiltrados = registrosFiltrados.filter((item) => item.dias >= 0);
      }

      if (filtros.disponibilidade === '1') {
        registrosFiltrados = registrosFiltrados.filter(
          (item) =>
            item.disponibilidade !== '0000-00-00' &&
            new Date(
              item.disponibilidade.substring(0, 4),
              Number(item.disponibilidade.substring(5, 7)) - 1,
              item.disponibilidade.substring(8, 10)
            ) >= new Date(ano, mes, dia)
        );
      }
      if (filtros.disponibilidade === '0') {
        registrosFiltrados = registrosFiltrados.filter(
          (item) =>
            item.disponibilidade === '0000-00-00' ||
            new Date(
              item.disponibilidade.substring(0, 4),
              Number(item.disponibilidade.substring(5, 7)) - 1,
              item.disponibilidade.substring(8, 10)
            ) <= new Date(ano, mes, dia)
        );
      }

      if (filtros.menorQue !== '' && filtros.dias !== '') {
        registrosFiltrados = registrosFiltrados.filter((item) =>
          filtros.menorQue === '1'
            ? Number(compararDatas(item.data_chave)) <= Number(filtros.dias)
            : Number(compararDatas(item.data_chave)) >= Number(filtros.dias)
        );
      }

      if (filtros.pesquisa.length > 0) {
        registrosFiltrados = filtrarRegistros(registrosFiltrados, filtros.pesquisa, filtros.tipoPesquisa);
      }

      setState((prevState) => ({
        ...prevState,
        listagem: registrosFiltrados,
      }));
    }
  };

  const filtrarRegistros = (registros, pesquisa, tipoPesquisa) => {
    pesquisa = pesquisa.toUpperCase();

    return registros.filter((x) => {
      switch (tipoPesquisa) {
        case '0':
          return (
            String(x.cliente_id)?.includes(pesquisa) ||
            x.cliente?.toUpperCase().includes(pesquisa) ||
            x.razao_social?.toUpperCase().includes(pesquisa) ||
            x.cnpj?.includes(pesquisa) ||
            x.contrato?.includes(pesquisa)
          );
        case '1':
          return String(x.cliente_id)?.includes(pesquisa.toString());
        case '2':
          return x.cliente?.toUpperCase().includes(pesquisa) || x.razao_social.toUpperCase().includes(pesquisa);
        case '3':
          return x.cnpj?.includes(pesquisa);
        case '4':
          return x.contrato?.includes(pesquisa);
        default:
          return false;
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getListagem();
    }, 1000 * 60 * 3);

    return () => clearInterval(intervalId);
  }, [temListagem]);

  useEffect(() => {
    getListagem();
  }, []);

  useEffect(() => {
    aplicarFiltro();
  }, [
    filtros.situacao,
    filtros.pesquisa,
    filtros.tipoPesquisa,
    filtros.financeiro,
    filtros.disponibilidade,
    filtros.menorQue,
    filtros.dias,
  ]);

  const AbrirGerarChave = (cliente) => {
    setState({ ...state, modalOpen: true, modalCliente: cliente });
  };
  const FecharGerarChave = () => {
    setState({ ...state, modalOpen: false });
  };
  const FecharChaveParam = () => {
    setState({ ...state, modalChaveParam: false });
    getListagem();
  };
  const AbrirChaveParam = (chave) => {
    setState({ ...state, modalChaveParam: true, modalCliente: chave });
  };

  const AbrirInfosCliente = (cliente) => {
    setState({ ...state, modalInfoOpen: true, modalCliente: cliente });
  };

  const FecharInfos = () => {
    setState({ ...state, modalInfoOpen: false, modalCliente: {} });
  };

  const coletaDeChave = (cliente) => {
    Swal.fire({
      title: 'Coletar chave',
      text: `Deseja coletar a chave do cliente ${cliente.cliente} para atualização manual?\nData da chave: ${formataData(
        cliente.disponibilidade
      )}`,
      showDenyButton: true,
      denyButtonText: 'Não',
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const result = await api.put(
            `/v1/financeiro/cliente/param_service/${cliente.contrato.includes('/') ? cliente.contrato.replace('/', '') : cliente.contrato}`,
            { siaacnew: cliente.contrato.includes('/') ? true : false }
          );
          const dados = result.data.result[0];
          return dados;
        } catch (error) {
          Swal.showValidationMessage(`Falha na requisição: ${error.response.data}`);
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        AbrirChaveParam(result.value.chave);
      }
    });
  };

  const abrirAlertaTrocaDataParam = (info) => {
    Swal.fire({
      title: 'INFORMAÇÕES',
      html: `<pre style="white-space: pre-wrap">${info}</pre>`,
    });
  };

  return (
    <>
      <ModalChaveParam isOpen={state.modalChaveParam} onRequestClose={FecharChaveParam} chave={state.modalCliente} />
      <ModalGerarChave isOpen={state.modalOpen} onRequestClose={FecharGerarChave} cliente={state.modalCliente} />
      <ModalInfosParam isOpen={state.modalInfoOpen} onRequestClose={FecharInfos} cliente={state.modalCliente} />
      <div className={permissoesUsuario.financeiro === 1 ? 'ml-1 mr-1 mt-3 pb-3' : 'container ml-1 mr-1 shadow-lg rounded-2 mt-3 pb-3'}>
        <div className={permissoesUsuario.financeiro === 1 ? 'mx-5' : ''}>
          <Titulo titulo="Listagem da Situação de Chave SIAAC dos Clientes" retorno={'clientes'} />
        </div>
        <div className={permissoesUsuario.financeiro === 1 ? 'mx-5' : ''}>
          <div className="card card-body mt-3">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="cmbPosicao">Pesquisar por</label>
                <select
                  onChange={(e) => setFiltros({ ...filtros, tipoPesquisa: e.target.value })}
                  id="cmbPosicao"
                  className="form-select mb-2"
                  aria-label="Selecionar"
                >
                  <option value="0">Qualquer</option>
                  <option value="1">Código</option>
                  <option value="2" selected>
                    Razão Social/Fantasia
                  </option>
                  <option value="3">CNPJ</option>
                  <option value="4">Contrato</option>
                </select>
              </div>
              <div className="col-md-8 col-sm-12">
                <label htmlFor="floatingInput">Pesquisa</label>
                <input
                  onChange={(e) => setFiltros({ ...filtros, pesquisa: e.target.value })}
                  type="text"
                  className="form-control mb-2"
                  id="floatingInput"
                  placeholder="Pesquisar"
                  value={filtros.pesquisa}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-4">
                <label htmlFor="situacaoChave">Situação da Chave</label>
                <select
                  value={filtros.situacao}
                  onChange={(e) => setFiltros({ ...filtros, situacao: e.target.value })}
                  id="situacaoChave"
                  className="form-select mb-2"
                  aria-label="Selecionar"
                >
                  <option value="" selected>
                    Todas
                  </option>
                  <option value={0}>Expiradas</option>
                  <option value={1}>Não Expiradas</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-4">
                <label htmlFor="situacaoChave">Situação Financeira</label>
                <select
                  value={filtros.financeiro}
                  onChange={(e) => setFiltros({ ...filtros, financeiro: e.target.value })}
                  id="situacaoChave"
                  className="form-select mb-2"
                  aria-label="Selecionar"
                >
                  <option value="" selected>
                    Todas
                  </option>
                  <option value={0}>Inadimplentes</option>
                  <option value={1}>Adimplentes</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-4">
                <label htmlFor="disponibilidade">Chave Liberada</label>
                <select
                  value={filtros.disponibilidade}
                  onChange={(e) => setFiltros({ ...filtros, disponibilidade: e.target.value })}
                  id="disponibilidade"
                  className="form-select mb-2"
                  aria-label="Selecionar"
                >
                  <option value="" selected>
                    Todas
                  </option>
                  <option value={1}>Sim</option>
                  <option value={0}>Não</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-4">
                <label htmlFor="menorQue">Quantidade de dias</label>
                <div className="input-group">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {filtros.menorQue === '' ? 'Selecione' : filtros.menorQue === '1' ? 'Menor ou igual que' : 'Maior ou igual que'}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button className="dropdown-item" onClick={() => setFiltros((prevState) => ({ ...prevState, menorQue: '' }))}>
                        Selecione
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setFiltros((prevState) => ({ ...prevState, menorQue: '1' }))}>
                        Menor ou igual que
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setFiltros((prevState) => ({ ...prevState, menorQue: '0' }))}>
                        Maior ou igual que
                      </button>
                    </li>
                  </ul>
                  <input
                    type="number"
                    className="form-control"
                    value={filtros.dias}
                    onChange={(e) => setFiltros({ ...filtros, dias: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-5 mt-3">
          <div className="col-4 d-flex">
            <div className={styles.rectangle}></div>
            <div className="ms-2">- Cliente que está inadimplente</div>
          </div>
          <div className="col-4 d-flex">
            <ListaParamPDF
              listagem={state.listagem}
              filtros={Object.keys(filtros).reduce((acc, key) => {
                if (filtros[key] !== filtrosInicial[key]) {
                  acc[key] = filtros[key];
                }
                return acc;
              }, {})}
            />
          </div>
        </div>
        <div className={permissoesUsuario.financeiro === 1 ? 'table-responsive p-0 mt-4 mx-2' : 'table-responsive p-0 mt-4'}>
          {state.loading ? (
            <LoadingComponent />
          ) : state.listagem.length !== 0 ? (
            <table className="table table-bordered param">
              <thead className={styles.cabecalho}>
                <tr className="table-dark">
                  <th scope="col">Contrato</th>
                  <th scope="col">Nome</th>
                  <th className="text-center" scope="col">
                    Validade
                  </th>
                  <th className="text-center" scope="col">
                    Disponibilidade
                  </th>
                  <th className="text-center" scope="col">
                    Consulta Param
                  </th>
                  <th className="text-center" scope="col">
                    Coleta Param
                  </th>
                  {permissoesUsuario.financeiro === 1 ? (
                    <th className="text-center" scope="col">
                      Liberado
                    </th>
                  ) : null}
                  <th className="text-center" scope="col">
                    Liberação
                  </th>
                  {permissoesUsuario.financeiro === 1 ? (
                    <th className="text-center" scope="col">
                      Mensagem
                    </th>
                  ) : null}
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody className={styles.corpo}>
                {state.listagem.map((cliente) => {
                  return (
                    <ClienteRow
                      key={cliente.cliente_id}
                      cliente={cliente}
                      isAdminUsuario={isAdminUsuario}
                      abrirAlertaTrocaDataParam={abrirAlertaTrocaDataParam}
                      coletaDeChave={coletaDeChave}
                      AbrirInfosCliente={AbrirInfosCliente}
                      AbrirGerarChave={AbrirGerarChave}
                    />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>
              <span className="d-block text-center">
                <div>
                  <h5 className="text-secondary">Sem clientes na listagem!</h5>
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ListaParam;
