import { useEffect, useState } from 'react';
import ScrollButton from '../../../components/scrollButton/index.jsx';
import RowsConciliacao from './rows.jsx';
import api from '../../../service/api.js';
import { toast } from '../../../constants/toast.jsx';
import Titulo from '../../../components/titulo/index.jsx';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { ROUTE_FINANCEIRO_CONCILIACAO } from '../../../constants/routes.js';

const initialState = {
  pesquisa: '',
  clientes: [],
  listagem: [],
  ativos: null,
  chave: null,
  codigo: null,
  edits: [],
  conciliacao: {
    ativo: false,
    codigos: [],
  },
};

function ConciliacaoCliente() {
  const [state, setState] = useState(initialState);

  const url = useLocation();

  async function GetListaClientes() {
    api
      .get('/v1/clientes')
      .then((response) => {
        if (response.status === 200) {
          const responseSort = fnProcesarListaClientes(response.data);
          setState((prevState) => ({
            ...prevState,
            clientes: responseSort,
            listagem: responseSort,
          }));
        } else {
          if (response.status === 401) {
            console.log('Ocorreu algum erro na requisição de clientes: ' + response.statusText);
            setState(initialState);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setState(initialState);
        } else {
          console.log(err);
        }
      });
  }

  const fnProcesarListaClientes = (lista) => {
    const clientesAtivosComCodigo = [];
    const clientesAtivosSemCodigo = [];
    const clientesInativos = [];

    lista.forEach((cliente) => {
      if (cliente.ativo === 0) {
        clientesInativos.push(cliente);
      }
      if (cliente.ativo === 1 && cliente.codigo_sac_local !== '' && cliente.codigo_sac_local) {
        clientesAtivosComCodigo.push(cliente);
      }
      if (cliente.ativo === 1 && (cliente.codigo_sac_local === '' || !cliente.codigo_sac_local)) {
        clientesAtivosSemCodigo.push(cliente);
      }
    });

    const clientesAtivosComCodigoOrdenados = clientesAtivosComCodigo.sort((a, b) => {
      return Number(a.codigo_sac_local) - Number(b.codigo_sac_local);
    });

    const clientesAtivosSemCodigoOrdenados = clientesAtivosSemCodigo.sort((a, b) => {
      if (a.razao_social < b.razao_social) {
        return -1;
      }
      if (a.razao_social > b.razao_social) {
        return 1;
      }
      return 0;
    });
    const responseSort = [...clientesAtivosSemCodigoOrdenados, ...clientesAtivosComCodigoOrdenados, ...clientesInativos];

    return responseSort;
  };

  const handleEdit = (clienteId, field, value) => {
    const indexEditedClient = state.edits.findIndex((x) => Number(x.id) === Number(clienteId));
    const nomeCliente = state.clientes.find((x) => Number(x.id) === Number(clienteId)).nome_fantasia;
    if (indexEditedClient === -1) {
      const editedClient = {
        nome: nomeCliente,
        id: clienteId,
        [field]: value,
      };
      setState((prevState) => ({
        ...prevState,
        edits: [...prevState.edits, editedClient],
      }));
    } else {
      setState((prevState) => {
        const updateEdits = [...prevState.edits];
        updateEdits[indexEditedClient][field] = value;
        return {
          ...prevState,
          edits: updateEdits,
        };
      });
    }
  };

  function returnHtml(edits) {
    return `
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Cliente</th>
            <th scope="col">Alteração</th>
          </tr>
        </thead>
        <tbody>
          ${edits.map((cliente) => {
            return `
                <tr>
                  <td>${cliente.nome}</td>
                  <td>${
                    cliente.ativo !== undefined
                      ? `Ativo: ${cliente.ativo ? 'Sim' : 'Não'}`
                      : cliente.gerar_chave !== undefined
                      ? `Gerar chave: ${cliente.gerar_chave ? 'Sim' : 'Não'}`
                      : `Código: ${cliente.codigo_sac_local}`
                  }</td>
                </tr>
              `;
          })}
        </tbody>
      </table>
    `;
  }

  function SaveEditsInApi() {
    if (state.edits.length > 0) {
      try {
        Swal.fire({
          icon: 'question',
          title: 'Confirma as seguintes alterações?',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Limpar',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          denyButtonColor: '#5f5f5f',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          html: returnHtml(state.edits),
        }).then(async (result) => {
          if (result.isConfirmed) {
            let preencheuCodigo = {
              codigo_sac_local: false,
              codigos: [],
            };
            const erros = [];
            for (const edit of state.edits) {
              delete edit.nome;
              if (
                edit.codigo_sac_local &&
                edit.codigo_sac_local !== '' &&
                state.conciliacao.ativo &&
                state.conciliacao.codigos.includes(Number(edit.codigo_sac_local))
              ) {
                preencheuCodigo.codigo_sac_local = true;
                preencheuCodigo.codigos.push({ clienteId: edit.id, codigoFinanceiro: edit.codigo_sac_local });
              }
              const resp = await api.patch('/v1/clientes', {
                ...edit,
              });
              if (resp.status !== 200) {
                erros.push(edit);
              }
            }
            if (preencheuCodigo.codigo_sac_local) {
              if (erros.length > 0) {
                preencheuCodigo.codigos = preencheuCodigo.codigos.filter((codigo) => !erros.some((erro) => erro.id === codigo.clienteId));
              }

              const result = api.post('/v1/financeiro/conciliacao', preencheuCodigo.codigos);
              if (result.status !== 201) {
                toast.fire({
                  icon: 'error',
                  title: 'Erro ao liberar pagamentos para clientes!',
                });
              } else {
                window.location.href = ROUTE_FINANCEIRO_CONCILIACAO;
              }
            }
            toast.fire({
              icon: 'success',
              title: 'Alterações salvas com sucesso!',
              didDestroy: () => {
                setState((prev) => ({
                  ...prev,
                  edits: [],
                }));
                GetListaClientes();
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              },
            });
          }
          if (result.isDenied) {
            CancelEdit();
          }
        });
      } catch (error) {
        toast.fire({
          icon: 'error',
          title: error,
        });
      }
    }
  }

  function CancelEdit() {
    setState((prev) => ({
      ...prev,
      edits: [],
      listagem: [],
    }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setState((prev) => ({
      ...prev,
      listagem: prev.clientes,
    }));
  }

  async function GetPagamentosSemClientes() {
    const response = await api.get('/v1/financeiro/pagamentos/semcliente', {
      params: {
        status: 'N',
      },
    });
    if (response.status === 200) {
      const pagamentos = response.data;
      if (pagamentos.length > 0) {
        const codigosFinanceiros = pagamentos.map((pagamento) => pagamento.cod_financeiro_cliente);
        if (codigosFinanceiros.length > 0) {
          const codigosFinanceirosUnicos = [...new Set(codigosFinanceiros)];
          const codigosFinanceirosAindaNaoConciliados = codigosFinanceirosUnicos.filter(
            (codigo) => !state.clientes.some((cliente) => cliente.codigo_sac_local === codigo)
          );
          console.log('codigosFinanceirosAindaNaoConciliados :>> ', codigosFinanceirosAindaNaoConciliados);

          setState((prev) => ({
            ...prev,
            conciliacao: {
              ativo: true,
              codigos: codigosFinanceirosUnicos,
            },
          }));

          if (codigosFinanceirosAindaNaoConciliados.length > 0) {
            toast.fire({
              icon: 'info',
              title: 'Existem pagamentos sem cliente associado. Favor conciliar!',
              text: `Cód. Financeiros: ${codigosFinanceirosAindaNaoConciliados.join(', ')}`,
            });
          }
        }
      }
    }
  }

  useEffect(() => {
    if (state.clientes.length > 0) {
      GetPagamentosSemClientes();
    }
  }, [state.clientes]);

  useEffect(() => {
    GetListaClientes();
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Conciliação de Clientes" />
        <div className="p-3">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Código Financeiro</th>
                <th scope="col">Opções</th>
              </tr>
            </thead>
            <tbody>
              {state.listagem.map((cliente) => (
                <RowsConciliacao key={cliente.id} cliente={cliente} onEdit={handleEdit} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pb-3 d-flex justify-content-center">
          <button className="btn btn-primary mx-2" onClick={SaveEditsInApi}>
            Salvar
          </button>
          <button className="btn btn-danger mx-2" onClick={CancelEdit}>
            Cancelar
          </button>
        </div>
      </div>
      <ScrollButton />
    </>
  );
}

export default ConciliacaoCliente;
