import React, { useState } from 'react';
import { formataData, formataHora } from '../../../../util/maskdoc.js';
import styles from './table.module.css';

function LiberacoesTable(props) {
  const { liberacoes } = props;
  const [openStates, setOpenStates] = useState({});

  const toogleInfos = (codigo) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [codigo]: !prevState[codigo],
    }));
  };

  const legendaLiberacao = {
    PARAM_WEB: 'Pagamento',
    SAC_WEB: 'SAC',
    SIAACFIRE: 'Confiança',
  };

  return (
    <div className={`${styles.border} shadow`}>
      <table className="table table-sm table-hover">
        <thead>
          <tr className={styles.bgLiberacoes}>
            <th scope="col">ID</th>
            <th scope="col">Cliente</th>
            <th scope="col">Dt. Liberação</th>
            <th scope="col">Dias</th>
            <th scope="col">Por</th>
          </tr>
        </thead>
        <tbody>
          {liberacoes.map((liberacao) => (
            <React.Fragment key={liberacao.id}>
              <tr className={styles.tableHeader} onClick={() => toogleInfos(liberacao.id)}>
                <th scope="row">{liberacao.id}</th>
                <td title={liberacao.cliente}>
                  {liberacao.cliente.length > 20 ? liberacao.cliente.substring(0, 20) + '...' : liberacao.cliente}
                </td>
                <td>
                  {formataData(liberacao.data_hora_liberacao)} {formataHora(liberacao.data_hora_liberacao)}
                </td>
                <td>{liberacao.dias}</td>
                <td>{legendaLiberacao[liberacao.ultima_liberacao]}</td>
              </tr>
              {openStates[liberacao.id] && (
                <tr>
                  <td colSpan={5}>
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          {liberacao.ultima_liberacao === 'SAC_WEB' ? (
                            <>
                              <th scope="col">ID equipe</th>
                              <th scope="col">Data chave</th>
                            </>
                          ) : liberacao.ultima_liberacao === 'PARAM_WEB' ? (
                            <>
                              <th scope="col">Data chave</th>
                              <th scope="col">Coletada</th>
                              {liberacao.liberado === 'N' && liberacao.data_hora_coleta ? <th scope="col">Data/Hora Coleta</th> : null}
                            </>
                          ) : (
                            <>
                              <th scope="col">Data chave</th>
                              <th scope="col">Obs</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {liberacao.ultima_liberacao === 'SAC_WEB' ? (
                            <>
                              <td>{liberacao.usuario_id}</td>
                              <td>{formataData(liberacao.data_chave)}</td>
                            </>
                          ) : liberacao.ultima_liberacao === 'PARAM_WEB' ? (
                            <>
                              <td>
                                {liberacao.liberado === 'N'
                                  ? `${formataData(liberacao.data_chave)}`
                                  : `${formataData(liberacao.disponibilidade)}`}
                              </td>
                              <td>{liberacao.liberado === 'N' ? `Sim` : `Não`}</td>
                              {liberacao.liberado === 'N' && liberacao.data_hora_coleta ? (
                                <td scope="col">
                                  {formataData(liberacao.data_hora_coleta)} {formataHora(liberacao.data_hora_coleta)}
                                </td>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <td>{formataData(liberacao.data_chave)}</td>
                              <td>{liberacao.obs}</td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LiberacoesTable;
