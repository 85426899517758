import React, { useEffect, useState } from 'react';
import { formataData } from '../../../../util/maskdoc.js';
import { FormataSemana } from '../../../../util/utils.js';
import LoadingComponent from '../../../../components/loading-component/index.jsx';
import { useNavigate } from 'react-router-dom';
import { ROUTE_FINANCEIRO_CONCILIACAO } from '../../../../constants/routes.js';

export default function LiberarPagamentoSemCliente({ pagamentos }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [idsFinanceiros, setIdsFinanceiros] = useState([]);

  function FormataDataUTC(data) {
    const dataSplit = data.split('T')[0];
    const dataNovoHorario = dataSplit + 'T12:00:00';
    return dataNovoHorario;
  }

  function NavigateConciliacaoCliente() {
    navigate(`${ROUTE_FINANCEIRO_CONCILIACAO}#${idsFinanceiros.join('%')}`);
  }

  useEffect(() => {
    setLoading(true);
    const ids = pagamentos.map((pagamento) => pagamento.cod_financeiro_cliente);
    setIdsFinanceiros(ids);
    setLoading(false);
  }, [pagamentos]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      <table className="table table-sm table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Pagamento</th>
            <th scope="col">Vencimento</th>
            <th scope="col">Cód. Financeiro</th>
            <th scope="col">Data Chave</th>
            <th scope="col">Dias Liberação</th>
            <th scope="col">Semana</th>
          </tr>
        </thead>
        <tbody>
          {pagamentos.map((pagamento) => (
            <tr key={`${pagamento.codigo}`} className={Number(pagamento.dias) <= 0 ? 'table-danger' : ''}>
              <td>{pagamento.codigo}</td>
              <td>{formataData(pagamento.data_pagamento)}</td>
              <td>{formataData(pagamento.data_vencimento)}</td>
              <td>{pagamento.cod_financeiro_cliente}</td>
              <td>{formataData(pagamento.disponibilidade)}</td>
              <td>{pagamento.dias}</td>
              <td>{FormataSemana(new Date(FormataDataUTC(pagamento.disponibilidade)).getDay())}</td>
              <td>
                <button className="btn btn-sm btn-outline-dark" onClick={NavigateConciliacaoCliente}>
                  Associar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
