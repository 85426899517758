import Modal from 'react-modal/lib/components/Modal';
import Close from '../../assets/close.png';
import BtnCopy from '../../components/btn-copy/index.jsx';
import { useState } from 'react';
import api from '../../service/api.js';

Modal.setAppElement('#root');

export default function ModalGerarSenhaBackup(props) {
  const [arquivo, setArquivo] = useState('');
  const [senha, setSenha] = useState('');
  const Cancelar = () => {
    props.onRequestClose();
  };

  async function GetSenhaBackup() {
    const response = await api.get('/v1/sistema/senha/backup', { params: { nomeArquivo: arquivo } });
    setSenha(response.data);
  }

  const customStyles = {
    content: { width: '400px' },
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <button type="button" onClick={Cancelar} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container-fluid h-100 endereco-modal">
        <div className="col-12 mt-0 d-flex align-items-center justify-content-center">
          <h4 className="mt-2-mb-4">Gerador de Senha do backup</h4>
        </div>
        <div className="d-flex align-items-center my-4 justify-content-center">
          <div className="">
            <label htmlFor="floatingInput">Nome do Arquivo sem a extensão</label>
            <input
              type="text"
              className="form-control mb-2"
              id="floatingInput"
              placeholder=""
              value={arquivo}
              onChange={(e) => setArquivo(e.target.value)}
            />
          </div>
          <button type="button" className="btn btn-sm btn-primary mt-3 ms-2" onClick={GetSenhaBackup}>
            Gerar
          </button>
        </div>
        {senha ? (
          <div className="mt-1 mb-1 d-flex align-items-center justify-content-center">
            <h6 className="mt-2-mb-4 text-break">{senha}</h6>
            <BtnCopy text={senha} />
          </div>
        ) : null}
        <div className="row">
          <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
            <button type="button" className="btn btn-sm btn-danger" onClick={Cancelar}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
