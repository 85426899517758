import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import jsonp from 'jsonp';
import { FaList, FaMagnifyingGlass, FaPlus } from 'react-icons/fa6';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { maskdoc, formataData, maskCEP, maskphone } from '../../../util/maskdoc';
import './style.css';
import { UsuarioContext } from '../../../context/userContext';
import { ST__IDUSUARIO } from '../../../constants/storagekey';
import { toast } from '../../../constants/toast.jsx';
import FormsModalPedidoMobile from '../../../components/cadastroPedidoMobile/index.jsx';
import ModalListagemVendedores from '../../../components/modalListagemVendedores/index.jsx';
import { ROUTE_CLIENTES } from '../../../constants/routes.js';
import Titulo from '../../../components/titulo/index.jsx';
import { GetCliente as GetClienteFetch, GetAlertasClienteFetch, GetCidade } from '../../../service/fetchers/clientes.js';
import {
  AlterarCliente,
  InativarAlertaCliente,
  InserirAlertaCliente,
  NovoCliente as NovoClienteRequest,
} from '../../../service/mutations/clientes.js';

function ClienteEdicao() {
  const queryClient = useQueryClient();
  const { permissoesUsuario, idUsuario } = useContext(UsuarioContext);

  const { id } = useParams();
  const navigate = useNavigate();

  const [tipoEdicao, setTipoEdicao] = useState('Tipo de Edição');
  const [idCliente, setIdCliente] = useState(0);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [contrato, setContrato] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [proprietario, setProprietario] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [codigoIbge, setCodigoIbge] = useState('');
  const [dataInicioContrato, setDataInicioContrato] = useState('');
  const [dataFimContrato, setDataFimContrato] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [situacaoFinanceira, setSituacaoFinanceira] = useState('A');
  const [codigoFinanceiro, setCodigoFinanceiro] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [usaParam, setUsaParam] = useState(true);
  const [cda, setCda] = useState(false);
  const [senhasAcesso, setSenhasAcesso] = useState('');
  const [observacao, setObservacao] = useState('');
  const [crt, setCrt] = useState(0);
  const [tributacao, setTributacao] = useState(0);
  const [informarContrato, setInformarContrato] = useState(false);
  const [editServicos, setEditServicos] = useState(false);

  const [servicos, setServicos] = useState({
    atacado: {
      pedidosMobile: false,
      qntVendedores: '',
      listaVendedores: [],
    },
    cotacao: {
      cotacaoWeb: false,
    },
    orcamento: false,
    consultaGeral: false,
  });

  const [usaPedidosMobile, setUsaPedidosMobile] = useState(false);

  const [modalCadastroVendedorOpen, setModalCadastroVendedorOpen] = useState(false);
  const [modalListagemVendedoresOpen, setModalListagemVendedoresOpen] = useState(false);

  function alterarCrt(valor) {
    if (valor !== '3') {
      setTributacao('0');
    }
    setCrt(valor);
  }

  function fecharModalListagemVendedores() {
    setModalListagemVendedoresOpen(false);
  }

  function fecharModalCadastroVendedor() {
    setModalCadastroVendedorOpen(false);
  }

  function updateEditServicos() {
    setEditServicos([6, 13, 37, 20, 25, 12, 18, 15].indexOf(Number(idUsuario)) !== -1);
  }
  useEffect(() => {
    updateEditServicos();
  }, [idUsuario]);

  function atualizaInformacaoServicos({ servico, campo, valor }) {
    setServicos((prevState) => {
      return { ...prevState, [servico]: { ...prevState[servico], [campo]: valor } };
    });
  }

  async function GetCliente() {
    try {
      const response = await queryClient.fetchQuery({
        queryKey: ['clientes', id],
        queryFn: () => GetClienteFetch(id),
        staleTime: 1000 * 60 * 10,
      });
      if (response) {
        setIdCliente(response[0].ID);
        setRazaoSocial(response[0].RAZAO_SOCIAL);
        setNomeFantasia(response[0].NOME);
        setContrato(response[0].CONTRATO);
        setCnpj(response[0].CNPJ);
        setIe(response[0].INSCRICAO_ESTADUAL);
        setProprietario(response[0].CONTATO);
        setEndereco(response[0].ENDERECO);
        setComplemento('');
        setBairro(response[0].BAIRRO);
        setCep(response[0].CEP);
        setCidade(response[0].nome_cidade);
        setUf(response[0].uf);
        setCodigoIbge(response[0].ID_CIDADEIBGE);
        setDataInicioContrato(response[0].DATA_CONTRATO_INICIO);
        setDataFimContrato(response[0].DATA_CONTRATO_FIM);
        setTelefone1(response[0].TELEFONE1);
        setTelefone2(response[0].TELEFONE2);
        setCelular(response[0].CELULAR);
        setEmail(response[0].EMAIL);
        setSenhasAcesso(response[0].senhas_acesso);
        setObservacao(response[0].OBSERVACAO);
        setSituacaoFinanceira(response[0].situacao_financeira);
        setCodigoFinanceiro(response[0].codigo_sac_local);
        setAtivo(Number(response[0].ativo) === 1);
        setUsaParam(response[0].param_web === 'S');
        setCda(response[0].cda === 'S');
        setServicos((prevServicos) => ({
          ...prevServicos,
          ...response[0].services,
        }));
        if (response[0].services?.atacado?.pedidosMobile) {
          setUsaPedidosMobile(true);
        }
        setTributacao(response[0].tributacao);
        setCrt(response[0].crt);
      }
    } catch (error) {
      toast.fire({
        icon: 'error',
        title: 'Não foi possível buscar informações do cliente',
        text: error.message,
      });
    }
  }

  const { data: alertas } = useQuery({
    queryKey: ['alertas_clientes', id],
    queryFn: () => GetAlertasClienteFetch(id),
    staleTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (id > 0) {
      setTipoEdicao('Alterar Cliente...');
      GetCliente();
    } else {
      setTipoEdicao('Novo Cliente...');
      setDataInicioContrato(new Date().toISOString());
    }
  }, []);

  const BuscarCidade = async ({ uf, nome }) => {
    const response = await queryClient.fetchQuery({
      queryKey: ['cidade', uf, nome],
      queryFn: () => GetCidade({ uf, nome }),
      staleTime: Infinity,
    });

    return response;
  };

  function GetCep() {
    const cepConsulta = cep.replace(/\D/g, '');
    fetch(`https://brasilapi.com.br/api/cep/v2/${cepConsulta}`)
      .then((res) => res.json())
      .then(async (data) => {
        if (data) {
          setEndereco(data.street ? data.street : '');
          setBairro(data.neighborhood ? data.neighborhood : '');
          const cidade = await BuscarCidade({ uf: data.state, nome: data.city });
          if (cidade) {
            setCidade(cidade[0].nome);
            setUf(cidade[0].uf);
            setCodigoIbge(cidade[0].id);
          }
        }
      });
  }

  async function GetCnpj(cnpj) {
    const receitaUrl = `https://receitaws.com.br/v1/cnpj/${cnpj.replace(/\D/g, '')}`;
    jsonp(receitaUrl, { timeout: 3000 }, async (err, response) => {
      if (err) {
        console.log('err :>> ', err);
        toast.fire({
          icon: 'error',
          text: 'Ocorreu um erro ao solicitar a informação na Receita Federal, tente novamente daqui a pouco.',
        });
      }
      if (response) {
        const { nome, fantasia, logradouro, numero, bairro, municipio, uf, telefone, email, complemento, cep, qsa } = response;

        setRazaoSocial(nome);
        setNomeFantasia(fantasia);
        setCep(cep);
        setEndereco(`${logradouro}, Nº${numero}`);
        setComplemento(complemento);
        setBairro(bairro);
        setCidade(municipio);
        setUf(uf);
        const telefones = telefone.split(' / ');
        setTelefone1(telefones[0]);
        setTelefone2(telefones[1] || '');
        setCelular(telefones[2] || '');
        setEmail(email);
        const sortedQsa = qsa.sort((a, b) => a.nome.localeCompare(b.nome));
        const primeiro = sortedQsa.length > 0 ? sortedQsa[0].nome : '';
        setProprietario(primeiro);

        const cidade = await BuscarCidade({ uf, nome: municipio });
        cidade && setCodigoIbge(cidade[0].id);
      }
    });
  }

  const activeClient = () => {
    setAtivo(!ativo);
  };

  const useParamOnClient = () => {
    setUsaParam(!usaParam);
  };

  const { mutateAsync: InserirAlerta } = useMutation({
    mutationFn: (alerta) => InserirAlertaCliente(alerta),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['alertas_clientes', id],
        });
      }, 1000);
      toast.fire({
        icon: 'success',
        title: 'Criado com sucesso',
      });
    },
    onError: (error) => {
      toast.fire({
        icon: 'error',
        title: 'Erro ao inserir alerta',
        text: error.response.data.message,
      });
    },
  });

  const reqInserirAlerta = (alerta) => {
    const objAlerta = { id_cliente: id, alerta, id_usuario_lancamento: idUsuario || localStorage.getItem(ST__IDUSUARIO) };
    InserirAlerta(objAlerta);
  };

  const modalNovoAlerta = (e) => {
    e.preventDefault();
    Swal.fire({
      showCancelButton: true,
      title: 'Cadastrar um novo alerta para o Cliente',
      inputLabel: 'Insira o alerta do Cliente (máx. 50 caracteres):',
      input: 'text',
      inputAttributes: {
        maxLength: 50,
        maxLines: 2,
      },
      preConfirm: (texto) => {
        if (texto) {
          reqInserirAlerta(texto);
        }
      },
    });
  };

  const { mutateAsync: InativarAlertaClientePatch } = useMutation({
    mutationFn: (id) => InativarAlertaCliente(id),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['alertas_clientes', id], (old) => old.filter((alerta) => alerta.id !== variables));
    },
    onError: (error) => {
      Swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).fire({
        icon: 'error',
        text: error.message,
      });
    },
  });

  const desativarAlerta = (e, idAlerta) => {
    e.preventDefault();
    Swal.fire({
      title: 'Deseja inativar o alerta?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        InativarAlertaClientePatch(idAlerta);
      }
    });
  };

  const { mutateAsync: NovoClientePost } = useMutation({
    mutationFn: (cliente) => NovoClienteRequest(cliente),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['clientes'],
      });
      Swal.fire({
        icon: 'success',
        title: 'Cliente registrado!',
        confirmButtonText: 'OK',
        preConfirm: () => {
          navigate(ROUTE_CLIENTES);
        },
      });
    },
    onError: (error) => {
      const message = Array.isArray(error.response.data) ? error.response.data.join(', \n') : error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Erro ao inserir Cliente',
        text: message,
      });
    },
  });

  const NovoCliente = () => {
    const servicosCliente = Object.assign({}, servicos);
    delete servicosCliente.atacado.listaVendedores;
    if (informarContrato && !contrato) {
      Swal.fire({
        icon: 'error',
        title: 'Contrato não informado',
        text: 'Informe o contrato do cliente ou desmarque a opção "Informar Contrato".',
      });
      return;
    }
    NovoClientePost({
      nome: nomeFantasia,
      razao_social: razaoSocial,
      endereco,
      bairro,
      id_cidadeibge: codigoIbge,
      cep,
      telefone1,
      telefone2,
      celular,
      email,
      contato: proprietario,
      contrato: informarContrato ? contrato : null,
      observacao,
      data_contrato_inicio: dataInicioContrato,
      cnpj: cnpj.replace(/\D/g, ''),
      inscricao_estadual: ie,
      codigo_sac_local: codigoFinanceiro,
      ativo: ativo ? '1' : '0',
      situacao_financeira: situacaoFinanceira,
      senhas_acesso: senhasAcesso,
      param_web: usaParam ? 'S' : 'N',
      cda: cda ? 'S' : 'N',
      situacao: 'A',
      servicos: servicosCliente,
      crt,
      tributacao,
    });
  };

  const { mutateAsync: AlterarClientePatch } = useMutation({
    mutationFn: (cliente) => AlterarCliente(cliente),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['clientes'],
      });
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      }).fire({ title: 'Cliente atualizado', icon: 'success' });
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao editar Cliente',
        text: error.response.data.message,
      });
    },
  });

  const EditarCliente = (e) => {
    const servicosCliente = Object.assign({}, servicos);
    delete servicosCliente.atacado.listaVendedores;
    if (servicosCliente.atacado.pedidosMobile === false && usaPedidosMobile === true) {
      Swal.fire({
        icon: 'question',
        title: 'Atenção',
        text: 'Para desativar o uso do Pedidos Mobile, o sistema irá desativar e resetar todos os vendedores vinculados ao cliente. Deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        preConfirm: (result) => {
          if (result) {
            req();
          }
        },
      });
    } else {
      req();
    }

    function req() {
      AlterarClientePatch({
        id,
        nome: nomeFantasia,
        razao_social: razaoSocial,
        endereco,
        bairro,
        id_cidadeibge: codigoIbge,
        cep,
        telefone1,
        telefone2,
        celular,
        email,
        contato: proprietario,
        contrato,
        observacao,
        cnpj: cnpj.replace(/\D/g, ''),
        inscricao_estadual: ie,
        ativo: ativo ? '1' : '0',
        situacao_financeira: situacaoFinanceira,
        senhas_acesso: senhasAcesso,
        param_web: usaParam ? 'S' : 'N',
        cda: cda ? 'S' : 'N',
        servicos: servicosCliente,
        crt,
        tributacao,
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (id == 0) {
      NovoCliente(e);
    }
    if (id > 0) {
      EditarCliente(e);
    }
  };

  return (
    <div>
      <FormsModalPedidoMobile isOpen={modalCadastroVendedorOpen} onRequestClose={fecharModalCadastroVendedor} cnpj={cnpj} id={id} />
      <ModalListagemVendedores isOpen={modalListagemVendedoresOpen} onRequestClose={fecharModalListagemVendedores} cnpj={cnpj} id={id} />
      <div className="container shadow-lg rounded-2 mt-3">
        <Titulo titulo={tipoEdicao} retorno="clientes" />
        <div className="row ps-2 mt-3">
          <div className="row mb-3 gx-2 gy-2 ps-3 pe-3" data-bss-baguettebox>
            <form id="form-cliente" action="">
              <div className="row">
                <div className={`mb-3 col-md-4 col-lg-${id === '0' ? '2' : '3'} col-sm-4`}>
                  <div className="form-floating">
                    <input
                      value={idCliente}
                      type="text"
                      className="form-control"
                      id="codigoCliente"
                      name="codigoCliente"
                      placeholder="Código"
                      disabled
                    />
                    <label htmlFor="codigoCliente" className="obrigatorio">
                      Código
                    </label>
                  </div>
                </div>

                <div className={`mb-3 col-md-8 col-lg-${id === '0' ? '4 d-flex flex-row' : '3'} col-sm-8`}>
                  {id === '0' ? (
                    <div
                      title="Caso o contrato não seja informado, o sistema irá gerar um contrato automaticamente."
                      className="form-group form-switch d-flex align-items-center justify-content-end"
                    >
                      <input
                        type="checkbox"
                        role="switch"
                        aria-checked={informarContrato}
                        className="form-check-input me-1"
                        id="cda"
                        checked={informarContrato}
                        onChange={() => setInformarContrato(!informarContrato)}
                        disabled={permissoesUsuario.editar_cliente === 0}
                      />
                      <span className="custom-control-description">Informar Contrato?</span>
                    </div>
                  ) : null}
                  <div className="form-floating">
                    <input
                      onChange={(e) => setContrato(e.target.value)}
                      value={contrato}
                      type="text"
                      className="form-control"
                      id="contrato"
                      name="contrato"
                      placeholder="Contrato"
                      disabled={permissoesUsuario.editar_cliente === 0 || (informarContrato === false && id === '0')}
                    />
                    <label htmlFor="contrato" className="obrigatorio">
                      Contrato
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-md-6 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setDataInicioContrato(e.target.value)}
                      value={formataData(dataInicioContrato)}
                      type="text"
                      className="form-control"
                      id="data-inicio-contrato"
                      name="data-inicio-contrato"
                      placeholder="data-inicio-contrato"
                      disabled
                    />
                    <label htmlFor="data-inicio-contrato" className="obrigatorio">
                      Data início contrato
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-md-6 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setDataFimContrato(e.target.value)}
                      value={formataData(dataFimContrato)}
                      type="text"
                      className="form-control"
                      id="data_contrato_fim"
                      name="data_contrato_fim"
                      placeholder=""
                      disabled
                    />
                    <label htmlFor="data_contrato_fim" className="obrigatorio">
                      Data fim contrato
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-md-6 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <label htmlFor="cnpj" className="obrigatorio">
                      CNPJ/CPF
                    </label>
                    <div className="input-group cnpj-cadastro-cliente">
                      <input
                        onChange={(e) => setCnpj(e.target.value)}
                        value={maskdoc(cnpj)}
                        type="text"
                        className="form-control"
                        id="cnpj"
                        name="cnpj"
                        placeholder="CNPJ/CPF"
                        disabled={permissoesUsuario.editar_cliente === 0}
                      />
                      <button
                        onClick={() => GetCnpj(cnpj)}
                        className="btn btn-secondary"
                        disabled={permissoesUsuario.editar_cliente === 0}
                        type="button"
                        id="button-addon1"
                      >
                        <FaMagnifyingGlass style={{ marginBottom: 2 }} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-3 col-md-6 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setIe(e.target.value)}
                      value={ie}
                      type="text"
                      className="form-control"
                      id="inscricao_estadual"
                      name="inscricao_estadual"
                      placeholder="CNPJ"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="inscricao_estadual">Inscrição Estadual</label>
                  </div>
                </div>

                <div className="mb-3 col-md-12 col-lg-6 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setProprietario(e.target.value)}
                      value={proprietario}
                      type="text"
                      className="form-control"
                      id="proprietario"
                      name="proprietario"
                      placeholder="Nome do Proprietário"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="proprietario" className="obrigatorio">
                      Proprietário
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-lg-6 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setRazaoSocial(e.target.value)}
                      value={razaoSocial}
                      type="text"
                      className="form-control"
                      id="razao_social"
                      name="razao_social"
                      placeholder="Razão Social"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="razao_social" className="obrigatorio">
                      Razão Social
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-6 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setNomeFantasia(e.target.value)}
                      value={nomeFantasia}
                      type="text"
                      className="form-control"
                      id="nome"
                      name="nome"
                      placeholder="Nome de Fantasia"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="nome" className="obrigatorio">
                      Nome de Fantasia
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <label htmlFor="cep" className="obrigatorio">
                      CEP
                    </label>
                    <div className="input-group cep-cadastro-cliente">
                      <input
                        onChange={(e) => setCep(e.target.value)}
                        value={maskCEP(cep)}
                        type="text"
                        className="form-control"
                        id="cep"
                        name="cep"
                        placeholder="CEP"
                        disabled={permissoesUsuario.editar_cliente === 0}
                      />
                      <button
                        onClick={GetCep}
                        className="btn btn-secondary"
                        disabled={permissoesUsuario.editar_cliente === 0}
                        type="button"
                        id="button-addon2"
                      >
                        <FaMagnifyingGlass style={{ marginBottom: 2 }} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-3 col-lg-6 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setEndereco(e.target.value)}
                      value={endereco}
                      type="text"
                      className="form-control"
                      id="endereco"
                      name="endereco"
                      placeholder="Endereço"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="endereco" className="obrigatorio">
                      Endereço
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setComplemento(e.target.value)}
                      value={complemento}
                      type="text"
                      className="form-control"
                      id="complemento"
                      name="complemento"
                      placeholder="Complemento"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="complemento" className="obrigatorio">
                      Complemento
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-lg-5 col-sm-12">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setBairro(e.target.value)}
                      value={bairro}
                      type="text"
                      className="form-control"
                      id="bairro"
                      name="bairro"
                      placeholder="Bairro"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="bairro" className="obrigatorio">
                      Bairro
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-1 col-sm-2">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setUf(e.target.value)}
                      value={uf}
                      type="text"
                      className="form-control"
                      id="uf"
                      name="uf"
                      placeholder="UF"
                      disabled
                    />
                    <label htmlFor="uf" className="obrigatorio">
                      UF
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-6 col-sm-10">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setCidade(e.target.value)}
                      value={cidade}
                      type="text"
                      className="form-control"
                      id="cidade"
                      name="cidade"
                      placeholder="Cidade"
                      disabled
                    />
                    <label htmlFor="cidade" className="obrigatorio">
                      Cidade
                    </label>
                    <input id="id_cidadeibge" value={codigoIbge} type="hidden" />
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setTelefone1(e.target.value)}
                      value={maskphone(telefone1)}
                      type="text"
                      className="form-control"
                      id="telefone1"
                      name="telefone1"
                      placeholder="Telefone"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="telefone1" className="">
                      Telefone
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setTelefone2(e.target.value)}
                      value={maskphone(telefone2)}
                      type="text"
                      className="form-control"
                      id="telefone2"
                      name="telefone2"
                      placeholder="Telefone"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="telefone2" className="">
                      Telefone
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setCelular(e.target.value)}
                      value={maskphone(celular)}
                      type="text"
                      className="form-control"
                      id="celular"
                      name="celular"
                      placeholder="Celular"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="celular" className="">
                      Celular
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <label htmlFor="email">E-mail</label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-3 mb-3">
                  <div className="form-floating">
                    <select
                      className="form-control"
                      id="situacao_financeira"
                      placeholder="Situação Financeira"
                      onChange={(e) => setSituacaoFinanceira(e.target.value)}
                      value={situacaoFinanceira}
                      disabled={permissoesUsuario.editar_cliente === 0}
                    >
                      <option value="A">Adimplente</option>
                      <option value="I">Inadimplente</option>
                      <option value="C">Cortesia</option>
                    </select>
                    <label htmlFor="situacao_financeira">Situação financeira</label>
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="codigo_sac_local"
                      placeholder="Código financeiro"
                      readOnly={permissoesUsuario.editar_cliente === 0 || tipoEdicao === 'Alterar Cliente...'}
                      onChange={(e) => setCodigoFinanceiro(e.target.value)}
                      value={codigoFinanceiro}
                      disabled={permissoesUsuario.editar_cliente === 0 || tipoEdicao === 'Alterar Cliente...'}
                    />
                    <label htmlFor="codigo_sac_local">Código financeiro</label>
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-floating">
                    <select
                      className="form-control"
                      id="CRT"
                      placeholder="Situação Financeira"
                      onChange={(e) => alterarCrt(e.target.value)}
                      value={crt}
                      disabled={permissoesUsuario.editar_cliente === 0}
                    >
                      <option value="0">Não Informado</option>
                      <option value="1">1 - Simples</option>
                      <option value="2">2 - Simples Nacional</option>
                      <option value="3">3 - Regime Normal</option>
                    </select>
                    <label htmlFor="CRT">CRT</label>
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-floating">
                    <select
                      className="form-control"
                      id="tributacao"
                      placeholder="Situação Financeira"
                      onChange={(e) => setTributacao(e.target.value)}
                      value={tributacao}
                      disabled={permissoesUsuario.editar_cliente === 0 || String(crt) !== '3'}
                    >
                      <option value="0">Não Informado</option>
                      <option value="1">Lucro Real</option>
                      <option value="2">Lucro Presumido</option>
                    </select>
                    <label htmlFor="tributacao">Tributação</label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-3 mb-3">
                  <div className="form-group form-switch d-flex align-items-center">
                    <br />
                    <input
                      type="checkbox"
                      role="switch"
                      aria-checked={cda}
                      className="form-check-input me-1"
                      id="cda"
                      checked={cda}
                      onChange={() => setCda(!cda)}
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <span className="custom-control-description">CDA</span>
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-group form-switch d-flex align-items-center">
                    <br />
                    <input
                      type="checkbox"
                      role="switch"
                      aria-checked={ativo}
                      className="form-check-input me-1"
                      id="ativo"
                      checked={ativo}
                      onChange={activeClient}
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <span className="custom-control-description">Ativo</span>
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-group form-switch d-flex align-items-center">
                    <br />
                    <input
                      type="checkbox"
                      role="switch"
                      aria-checked={usaParam}
                      className="form-check-input me-1"
                      id="param_web"
                      checked={usaParam}
                      onChange={useParamOnClient}
                      disabled={permissoesUsuario.editar_cliente === 0}
                    />
                    <span className="custom-control-description text-wrap">Usa Param Web</span>
                  </div>
                </div>
              </div>
              {editServicos ? (
                <div className="row my-1">
                  <div>
                    <a
                      className="fs-6 text text-decoration-none titulo"
                      data-bs-toggle="collapse"
                      href="#servicos"
                      aria-expanded="false"
                      aria-controls="servicos"
                    >
                      Outros Serviços (Web/Mobile)
                    </a>
                  </div>
                  {/* ATACADO(pedido mobile), ORÇAMENTO, CONSULTAS GERAL(mobile), COTAÇÃO (web/mobile), CATALOGO DE PRODUTOS*/}
                  <div className="collapse mt-2" id="servicos">
                    {/* --ATACADO-- */}
                    <div className="card p-2">
                      <h6 className="fw-normal">Atacado:</h6>
                      <div className="row mt-2 ms-2">
                        <div className="col-6 col-md-4 d-flex align-items-center">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="usaPedidosMobile"
                              checked={servicos.atacado.pedidosMobile}
                              onChange={(e) =>
                                atualizaInformacaoServicos({ servico: 'atacado', campo: 'pedidosMobile', valor: e.target.checked })
                              }
                            />
                            <label className="form-check-label" for="usaPedidosMobile">
                              Pedidos Mobile
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 d-flex align-items-center">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              id="quantidadeVendedores"
                              onChange={(e) =>
                                atualizaInformacaoServicos({ servico: 'atacado', campo: 'qntVendedores', valor: e.target.value })
                              }
                              value={servicos.atacado.qntVendedores}
                              disabled={!servicos.atacado.pedidosMobile}
                            />
                            <label for="quantidadeVendedores">Máximo de Vendedores</label>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 d-flex align-items-center">
                          <button
                            className="btn btn-secondary d-flex align-items-center justify-content-between"
                            onClick={(e) => {
                              e.preventDefault();
                              setModalListagemVendedoresOpen(true);
                            }}
                            disabled={!servicos.atacado.pedidosMobile}
                          >
                            <FaList className="me-1" /> <span>Listagem de Vendedores</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* --COTACAO-- */}
                    {/* <div className="card p-2 mt-2">
                      <h6 className="fw-normal">Cotação:</h6>
                      <div className="row mt-2 ms-2">
                        <div className="col-6 col-md-3 d-flex align-items-center">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="usaCotacaoWeb"
                              checked={servicos.cotacao.cotacaoWeb}
                              onChange={(e) =>
                                atualizaInformacaoServicos({ servico: 'cotacao', campo: 'cotacaoWeb', valor: e.target.checked })
                              }
                            />
                            <label className="form-check-label" for="usaCotacaoWeb">
                              Utiliza Cotação Web
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : null}

              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group col-sm-12">
                    <label htmlFor="senhas_acesso">Senhas acesso remoto:</label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setSenhasAcesso(e.target.value)}
                      value={senhasAcesso}
                      id="senhas_acesso"
                      rows={10}
                      disabled={permissoesUsuario.editar_cliente === 0 && permissoesUsuario.editar_obs_cliente === 0}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group col-sm-12">
                    <label htmlFor="observacao">Observação:</label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setObservacao(e.target.value)}
                      value={observacao}
                      id="observacao"
                      rows={10}
                      maxLength={500}
                      disabled={permissoesUsuario.editar_cliente === 0 && permissoesUsuario.editar_obs_cliente === 0}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-cols-1 mt-2">
                <div className="col">
                  <a
                    className="btn btn-warning"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Alertas
                  </a>
                </div>
                <div className="row mt-1">
                  <div className="collapse" id="collapseExample">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                      {alertas && Array.isArray(alertas) && alertas.length > 0
                        ? alertas?.map((alerta) => (
                            <div key={alerta.id} className="col mt-2">
                              <div className="card card-body div-alerta">
                                <div className="row mx-0">
                                  <div className="col-10 border-end d-flex align-items-center justify-content-center">
                                    <span className="alerta">{alerta.alerta}</span>
                                  </div>
                                  <div className="col-2 d-flex justify-content-center align-items-center p-0">
                                    <button
                                      type="button"
                                      className="btn-close"
                                      aria-label="Close"
                                      onClick={(e) => {
                                        desativarAlerta(e, alerta.id);
                                      }}
                                      disabled={permissoesUsuario.remover_alerta_cliente === 0}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                      <div className="col d-flex align-items-center justify-content-start mt-2">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => modalNovoAlerta(e)}
                          disabled={permissoesUsuario.incluir_alerta_cliente === 0}
                        >
                          <FaPlus style={{ marginBottom: 2 }} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
                  <button onClick={(e) => submitForm(e)} className="btn btn-lg btn-outline-primary">
                    Gravar Dados
                  </button>
                  <button onClick={(e) => navigate(ROUTE_CLIENTES)} className="btn btn-lg btn-danger">
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClienteEdicao;
