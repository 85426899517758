import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import api from '../service/api.js';

export const DownloadsContext = createContext({
  downloads: [],
  addDownload: () => undefined,
  updateDownload: () => undefined,
  removeDownload: () => undefined,
  clearDownloads: () => undefined,
  cancelDownload: () => undefined,
  retryFailedDownloads: () => undefined,
});

export function DownloadsContextProvider({ children }) {
  const [downloads, setDownloads] = useState([]);

  const [queue, setQueue] = useState([]);
  const [activeDownloads, setActiveDownloads] = useState(0);
  const maxConcurrentDownloads = 4;

  // Ref to keep track of active download controllers
  const controllersRef = useRef({});

  function generateId() {
    return Math.random().toString(36).substring(2, 9);
  }

  function addDownload(download) {
    download.id = generateId();
    download.hasError = false;
    setDownloads((prevDownloads) => [...prevDownloads, download]);
    setQueue((prevQueue) => [
      ...prevQueue,
      ...download.arquivos.map((file) => ({
        downloadName: download.nome,
        id: download.id,
        file,
      })),
    ]);
  }

  const updateDownloadStatus = useCallback((id, fileName, status, processado = 0, porcentagem = 0) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id
          ? {
              ...download,
              arquivos: download.arquivos.map((file) =>
                file.nome === fileName
                  ? {
                      ...file,
                      status,
                      processado: processado ? processado : file.processado,
                      porcentagem: porcentagem ? porcentagem : file.porcentagem,
                    }
                  : file
              ),
            }
          : download
      )
    );
  }, []);

  const markErrorsInDownloads = useCallback(() => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) => ({
        ...download,
        hasError: download.arquivos.some((file) => file.status === 'E'),
      }))
    );
  }, []);

  const removeDownload = useCallback((id) => {
    setDownloads((prevDownloads) => prevDownloads.filter((download) => download.id !== id));
    delete controllersRef.current[id];
  }, []);

  const removerDownloadQueue = useCallback((id) => {
    setQueue((prevQueue) => prevQueue.filter((queue) => queue.id !== id));
  }, []);

  const downloadFile = useCallback(
    async (id, file) => {
      console.log('file :>> ', file);
      setActiveDownloads((prev) => prev + 1);
      const controller = new AbortController();
      controllersRef.current[id] = controller;
      try {
        const response = await api.get(file.url, {
          responseType: 'blob',
          signal: controller.signal,
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / file.tamanho);
            updateDownloadStatus(id, file.nome, 'P', progressEvent.loaded, percentCompleted);
          },
        });

        if (response?.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.setAttribute('download', file.nome);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);

          updateDownloadStatus(id, file.nome, 'C');
        } else {
          console.error('Empty response or no data found.');
          updateDownloadStatus(id, file.nome, 'E');
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Download cancelled:', file.nome);
        } else {
          console.error('Error during download:', error);
        }
        updateDownloadStatus(id, file.nome, 'E');
      } finally {
        setActiveDownloads((prev) => prev - 1);
        delete controllersRef.current[id];
      }
    },
    [updateDownloadStatus]
  );

  const cancelDownload = useCallback(
    (id) => {
      if (controllersRef.current[id]) {
        controllersRef.current[id].abort();
      }
      removeDownload(id);
      removerDownloadQueue(id);
    },
    [removeDownload, removerDownloadQueue]
  );

  const removeCompletedDownloads = useCallback(() => {
    setDownloads((prevDownloads) => prevDownloads.filter((download) => !download.arquivos.every((file) => file.status === 'C')));
  }, []);

  const handlerQueue = useCallback(async () => {
    const { id, file } = queue[0];
    setQueue((prevQueue) => prevQueue.slice(1));
    downloadFile(id, file);
  }, [downloadFile, queue]);

  useEffect(() => {
    if (activeDownloads < maxConcurrentDownloads && queue.length > 0) {
      handlerQueue();
    }

    // First mark errors without triggering too many updates
    markErrorsInDownloads();

    // Then remove completed downloads
    removeCompletedDownloads();
  }, [activeDownloads, queue, handlerQueue, markErrorsInDownloads, removeCompletedDownloads]);

  const retryFailedDownloads = useCallback(
    (id) => {
      const download = downloads.find((download) => download.id === id);
      download.hasError = false;
      const failedFiles = download.arquivos
        .filter((file) => file.status === 'E')
        .map((file) => {
          return { ...file, status: 'P' };
        });

      download.arquivos = failedFiles;

      setQueue((prevQueue) => [
        ...prevQueue,
        ...failedFiles.map((file) => ({
          downloadName: download.nome,
          id: download.id,
          file,
        })),
      ]);

      setDownloads((prevDownloads) => prevDownloads.map((d) => (d.id === id ? download : d)));
    },
    [downloads]
  );

  return (
    <DownloadsContext.Provider
      value={{
        downloads,
        addDownload,
        updateDownload: updateDownloadStatus,
        removeDownload,
        clearDownloads: () => setDownloads([]),
        cancelDownload,
        retryFailedDownloads,
      }}
    >
      {children}
    </DownloadsContext.Provider>
  );
}
