import api from '../api.js';

export async function NovoCliente(cliente) {
  try {
    const response = await api.post('/v1/clientes', cliente);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function AlterarCliente(cliente) {
  try {
    const response = await api.patch('/v1/clientes', cliente);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function InserirAlertaCliente(alerta) {
  try {
    const response = await api.post('/v1/alerta_clientes', alerta);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function InativarAlertaCliente(id) {
  try {
    const response = await api.patch(`/v1/alerta_clientes/`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
}
