import React, { useContext, useEffect, useState } from 'react';
import Titulo from '../../../components/titulo/index.jsx';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { GetListagemBancosCliente, GetLogsCliente } from '../../../service/fetchers/clientes.js';
import LoadingComponent from '../../../components/loading-component/index.jsx';
import { FaCaretDown, FaDownload, FaRegFaceDizzy } from 'react-icons/fa6';
import { formataData, formataHora } from '../../../util/maskdoc.js';
import api from '../../../service/api.js';
import BtnGerarSenha from '../btnGerarSenha.jsx';
import { DownloadsContext } from '../../../context/downloadsContext.jsx';
import { isValidDate } from '../../../util/utils.js';

const initialFiltros = {
  dataInicio: '',
  dataFim: '',
  sucesso: null,
  qnt: 15,
  limite: 15,
};

function LogsCliente() {
  const [filtros, setFiltros] = useState(initialFiltros);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [pagina, setPagina] = useState(1);

  const contrato = useParams().contrato;
  const cnpj = useParams().cnpj;
  const { addDownload } = useContext(DownloadsContext);
  const { data, isLoading, error } = useQuery({
    queryKey: ['logs-bkp-cliente', contrato, cnpj, filtros],
    queryFn: () => GetLogsCliente({ contrato, cnpj, ...filtros }),
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: dataDownloads,
    isLoading: isLoadingBancos,
    error: errorBancos,
  } = useQuery({
    queryKey: ['downloads'],
    queryFn: () => GetListagemBancosCliente({ contrato, cnpj }),
  });

  function formatarFrequencia(frequencia) {
    const { tipo, hora, minuto, diasSemana } = frequencia;

    switch (tipo) {
      case 'HoraMinuto':
        return `Executa a cada ${hora ? `${hora} hora${hora > 1 ? 's' : ''}` : ''}${hora && minuto ? ' e ' : ''}${
          minuto ? `${minuto} minuto${minuto > 1 ? 's' : ''}` : ''
        }.`;

      case 'Diaria':
        return `Executa diariamente às ${hora || '00'}:${minuto || '00'} h.`;

      case 'Semanal': {
        const diasFormatados = diasSemana.length > 0 ? diasSemana.join(', ') : 'sem dias especificados';
        return `Executa semanalmente às ${hora || '00'}:${minuto || '00'} h nos dias: ${diasFormatados}.`;
      }

      default:
        return 'Tipo de frequência desconhecido.';
    }
  }

  function DownloadArquivos(banco) {
    const statusDownload = {
      nome: banco.nome,
      arquivos: banco.arquivos.sort().map((arquivo) => ({
        nome: arquivo.name,
        tamanho: arquivo.size,
        status: 'P',
        url: `${api.defaults.baseURL}/v1/clientes/backup/download?cnpj=${cnpj}&contrato=${contrato}&banco=${banco.nome}&arquivo=${arquivo.name}`,
        processado: 0,
        porcentagem: 0,
      })),
    };
    addDownload(statusDownload);
  }

  function updateFiltros(campo, valor) {
    if (campo === 'dataInicio') {
      setDataInicio(valor);
      if (!isValidDate(valor)) {
        return;
      }
    }
    if (campo === 'dataFim') {
      setDataFim(valor);
      if (!isValidDate(valor)) return;
    }
    if (campo === 'qnt') {
      if (Number(valor) < 1) return;
      return setFiltros((prev) => ({
        ...prev,
        [campo]: Number(valor),
        limite: Number(valor) * pagina,
      }));
    }
    setFiltros((prev) => ({
      ...prev,
      [campo]: valor,
    }));
  }

  function nextPage() {
    setPagina((prev) => prev + 1);
    setFiltros((prev) => ({
      ...prev,
      limite: prev.qnt * (pagina + 1),
    }));
  }

  return (
    <div className="container ml-1 mr-1 shadow-lg rounded-2 mt-3 pb-3">
      <Titulo titulo="Backups do Cliente" retorno="clientes/backups" complementoTitulo={<BtnGerarSenha />} />

      {isLoading ? (
        <LoadingComponent />
      ) : error ? (
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center my-5">
            <h2 className="mt-2">
              <FaRegFaceDizzy />
            </h2>
            <h5 className="mt-2">Erro ao carregar os logs</h5>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <h5 className="mt-2">{data.nome}</h5>
            </div>
          </div>
          <div className="row">
            {isLoadingBancos ? (
              <p className=" card-text placeholder-glow">
                <span className="placeholder bg-primary col-2 fs-3" />
              </p>
            ) : errorBancos ? (
              <div>Erro ao carregar os bancos</div>
            ) : (
              <div>
                {dataDownloads
                  ? dataDownloads?.map((banco) => (
                      <button
                        key={banco.nome}
                        type="button"
                        className="btn btn-sm btn-primary ms-2"
                        onClick={() => DownloadArquivos(banco)}
                      >
                        {' '}
                        <FaDownload /> {banco.nome} {banco.tamanho}
                      </button>
                    ))
                  : null}
              </div>
            )}
            <h6 className="mt-2">Bancos:</h6>
          </div>

          <div className="row">
            <h6 className="mt-3">Logs:</h6>
            <div className="row">
              <div className="col">
                <label htmlFor="dataInicio">Data Inicial</label>
                <input
                  type="date"
                  className="form-control"
                  id="dataInicio"
                  value={dataInicio}
                  onChange={(e) => updateFiltros('dataInicio', e.target.value)}
                />
              </div>
              <div className="col">
                <label htmlFor="dataFim">Data Final</label>
                <input
                  type="date"
                  className="form-control"
                  id="dataFim"
                  value={dataFim}
                  onChange={(e) => updateFiltros('dataFim', e.target.value)}
                />
              </div>
              <div className="col">
                <label htmlFor="sucesso">Sucesso</label>
                <select
                  className="form-select"
                  id="sucesso"
                  value={filtros.sucesso}
                  onChange={(e) => updateFiltros('sucesso', e.target.value)}
                >
                  <option value={null}>Todos</option>
                  <option value={1}>Sucesso</option>
                  <option value={0}>Falha</option>
                </select>
              </div>
              <div className="col">
                <label htmlFor="qnt">Quantidade</label>
                <input
                  type="number"
                  className="form-control"
                  id="qnt"
                  value={filtros.qnt}
                  onChange={(e) => updateFiltros('qnt', e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="table table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Rotina</th>
                      <th>Banco</th>
                      <th>Data</th>
                      <th>Frequencia</th>
                      <th>Status</th>
                      <th>Mensagem</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ? data.logs?.map((log) => (
                          <tr key={log.idLog}>
                            <td>{log.nomeRotina}</td>
                            <td>{log.nomeBanco}</td>
                            <td>{`${formataData(log.data)} ${formataHora(log.data)}`}</td>
                            <td>{formatarFrequencia(log.frequencia)}</td>
                            <td>{log.sucesso ? 'Sucesso' : 'Falha'}</td>
                            <td>{log.log}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            {data?.logs?.length < filtros.limite ? null : (
              <span className="d-block text-center mt-3 mb-3">
                <button type="button" className="btn btn-lg btn-outline-secondary mb-3 border-0" onClick={nextPage}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <small>Carregar mais Logs</small>
                    <FaCaretDown />
                  </div>
                </button>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default LogsCliente;
