import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formataData, formataHora, maskdoc } from '../../util/maskdoc.js';
import PropTypes from 'prop-types';

function CardBackup({ backup }) {
  const navigate = useNavigate();
  function formatarFrequencia(frequencia) {
    const { tipo, hora, minuto, diasSemana } = frequencia;

    switch (tipo) {
      case 'HoraMinuto':
        return `Executa a cada ${hora ? `${hora} hora${hora > 1 ? 's' : ''}` : ''}${hora && minuto ? ' e ' : ''}${
          minuto ? `${minuto} minuto${minuto > 1 ? 's' : ''}` : ''
        }.`;

      case 'Diaria':
        return `Executa diariamente às ${hora || '00'}:${minuto || '00'} h.`;

      case 'Semanal': {
        const diasFormatados = diasSemana.length > 0 ? diasSemana.join(', ') : 'sem dias especificados';
        return `Executa semanalmente às ${hora || '00'}:${minuto || '00'} h nos dias: ${diasFormatados}.`;
      }

      default:
        return 'Tipo de frequência desconhecido.';
    }
  }

  function navigateToLogsCliente() {
    navigate(`/clientes/backups/${backup.contrato}/${backup.cnpj}`);
  }

  const legendaStatus = {
    1: <span className="badge text-bg-success">Sucesso</span>,
    0: <span className="badge text-bg-danger">Falha</span>,
  };

  return (
    <div className="container">
      <div className="card-cliente-container">
        <div className="tag-card-cliente-ativo" />
        <div className="container" onClick={() => navigateToLogsCliente()}>
          <div className="row">
            <div className="col">
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  <strong>{backup.nomeCliente}</strong>
                </span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  Contrato: <strong>{backup.contrato}</strong>
                </span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  CNPJ: <strong>{maskdoc(backup.cnpj)}</strong>
                </span>
              </div>
            </div>
            <div className="col">
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  Última Rotina: <strong>{backup.ultimaRotina}</strong>
                </span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  Banco: <strong>{backup.banco}</strong>
                </span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  Dt Registro: <strong>{`${formataData(backup.dtRegistro)} ${formataHora(backup.dtRegistro)}`}</strong>
                </span>
              </div>
            </div>
            <div className="col">
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>Status: {legendaStatus[backup.status ? 1 : 0]}</span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span>
                  Frequencia: <strong>{formatarFrequencia(backup.frequencia)}</strong>
                </span>
              </div>
              <div className="row py-1" style={{ overflow: 'hidden' }}>
                <span className={backup.atraso ? 'text-danger' : 'text-primary'}>
                  Próxima execução: <strong>{`${formataData(backup.proximaExecucao)} ${formataHora(backup.proximaExecucao)}`}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardBackup;

CardBackup.propTypes = {
  backup: PropTypes.shape({
    nomeCliente: PropTypes.string,
    contrato: PropTypes.string,
    cnpj: PropTypes.string,
    ultimaRotina: PropTypes.string,
    banco: PropTypes.string,
    dtRegistro: PropTypes.string,
    status: PropTypes.bool,
    frequencia: PropTypes.shape({
      tipo: PropTypes.string,
      hora: PropTypes.string,
      minuto: PropTypes.string,
      diasSemana: PropTypes.arrayOf(PropTypes.string),
    }),
    atraso: PropTypes.bool,
    proximaExecucao: PropTypes.string,
  }),
};
