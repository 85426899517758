import React, { useContext } from 'react';

import styles from './param.module.css'; // Ajuste o caminho conforme necessário
import { formataData, formataHora, maskphone } from '../../util/maskdoc.js';
import ModalSenhasClientes from '../../components/modalSenhas/index.jsx';
import { UsuarioContext } from '../../context/userContext.jsx';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FaKey, FaRegRectangleList } from 'react-icons/fa6';

const ClienteRow = React.memo(
  ({ cliente, isAdminUsuario, abrirAlertaTrocaDataParam, coletaDeChave, AbrirInfosCliente, AbrirGerarChave }) => {
    const { permissoesUsuario } = useContext(UsuarioContext);
    const dataDisponibilidadeParaComparacao = new Date(
      cliente.disponibilidade.substring(0, 4),
      Number(cliente.disponibilidade.substring(5, 7)) - 1,
      cliente.disponibilidade.substring(8, 10)
    );
    const formatTelefone = (telefone1, telefone2) => {
      const tel1 = telefone1 ? maskphone(telefone1) : null;
      const tel2 = telefone2 ? maskphone(telefone2) : null;
      let string = '';
      if (tel1) string = tel1;
      if (tel2 && !tel1) string = tel2;
      if (tel2 && tel1) string += ' / ' + tel2;
      return string;
    };

    const ano = new Date().getFullYear();
    const mes = new Date().getMonth();
    const dia = new Date().getDate();

    const diferencaEmDias = (data) => {
      const dataAtual = new Date();
      const dataFornecidaObj = new Date(data);
      const diferencaEmMilissegundos = dataFornecidaObj - dataAtual;
      const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
      return diferencaEmDias;
    };

    const legendaLiberacao = {
      PARAM_WEB: 'Pagamento',
      SAC_WEB: 'SAC',
      SIAACFIRE: 'CONFIANÇA',
    };

    return (
      <tr className={`${cliente.situacao === 'Adimplente.' ? '' : styles.inadimplente} ${styles.row}`}>
        <th title="Contrato" scope="row">
          <ModalSenhasClientes
            telefones={formatTelefone(cliente.telefone1, cliente.telefone2)}
            classeCSS={styles.modalNoFormat}
            senhas={cliente.senhas_acesso}
            cliente={cliente.cliente.toUpperCase()}
          >
            {cliente.contrato}
          </ModalSenhasClientes>
        </th>
        <td title="Cliente">
          <div>
            <a href={`/clientes/${cliente.cliente_id}`} target="_blank" rel="noreferrer">
              {cliente.cliente.toUpperCase()}
            </a>
          </div>
        </td>
        <td title="Dias a expirar" className={`text-center ${cliente.dias_expirar <= 0 ? styles.chaveExpirada : null}`}>
          <span>{cliente.status_chave}</span>{' '}
          {cliente.alterado === 'S' ? (
            <button className={styles.btnAlertaAlteracaoDtChave} onClick={() => abrirAlertaTrocaDataParam(cliente.data_chave_anterior)}>
              <FaExclamationTriangle title={cliente.data_chave_anterior} style={{ fontSize: 15, color: '#F50F0F' }} />
            </button>
          ) : (
            ''
          )}
        </td>
        <td>
          <div
            title={
              cliente.disponibilidade === '0000-00-00'
                ? 'Não a chave disponível.'
                : `Chave disponível até ${formataData(cliente.disponibilidade)}`
            }
          >
            {cliente.disponibilidade !== '0000-00-00' && dataDisponibilidadeParaComparacao >= new Date(ano, mes, dia) ? (
              <>
                <button onClick={() => coletaDeChave(cliente)} className={styles.chaveDisponivel} title="Coletar chave manualmente">
                  {formataData(cliente.disponibilidade)}
                </button>
                <br />
                <span>{diferencaEmDias(cliente.disponibilidade)} dias</span>
              </>
            ) : null}
          </div>
        </td>
        <td className={styles.datasParam}>
          <div>
            {formataData(cliente.ultima_consulta_param_web)} {formataHora(cliente.ultima_consulta_param_web)}
          </div>
        </td>
        <td className={styles.datasParam}>
          <div>
            {formataData(cliente.data_hora_coleta)} {formataHora(cliente.data_hora_coleta)}
          </div>
        </td>
        {permissoesUsuario.financeiro === 1 ? (
          <td scope="col">
            {formataData(cliente.data_hora_liberacao)} {formataHora(cliente.data_hora_liberacao)}
          </td>
        ) : null}
        <td scope="col">{legendaLiberacao[cliente.ultima_liberacao]}</td>
        {permissoesUsuario.financeiro === 1 ? <td scope="col">{cliente.mensagem}</td> : null}
        <td>
          <div className="d-flex align-items-center justify-content-center">
            <button className={styles.btnAcoes} onClick={() => AbrirInfosCliente(cliente)}>
              <FaRegRectangleList size={14} />
            </button>
            <button className={styles.btnAcoes} onClick={() => AbrirGerarChave(cliente)}>
              <FaKey size={14} />
            </button>
          </div>
        </td>
      </tr>
    );
  }
);

export default ClienteRow;
