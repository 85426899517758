import { useContext, useEffect, useRef, useState } from 'react';
import { BuscaClienteListaAutoCompleteContext } from '../../context/BuscaClienteListaAutoCompleteContext';
import './style.css';
import { ClientesContext } from '../../context/clientesContext.jsx';
import PropTypes from 'prop-types';

function ProcuraCliente({ editar }) {
  const [listaAutoComplete, setListaAutoComplete] = useState([]);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const { cursor, setCursor, focus, setFocus, cliente, setCliente, setAchouCliente, setClienteId, clienteId } = useContext(
    BuscaClienteListaAutoCompleteContext
  );

  const { AtualizarListagemClientes: fetchApi, clientes: listaClientes } = useContext(ClientesContext);

  function autoCompleteNomeClientes(nome) {
    if (nome.length <= 1) {
      setCliente('');
      setClienteId('');
      setListaAutoComplete([]);
    } else {
      let data = [];
      data = listaClientes.filter((x) => {
        if (
          x.nome_fantasia?.toUpperCase().includes(nome.toUpperCase()) ||
          x.razao_social?.toUpperCase().includes(nome.toUpperCase()) ||
          x.cnpj?.includes(nome)
        ) {
          return x;
        }
      });
      data > 0 && atualizarLista && setAtualizarLista(false);
      setListaAutoComplete(data);
      setCursor(-1);
    }
  }

  async function AtualizarListagemClientes(e) {
    e.preventDefault();
    setAtualizarLista(true);
    fetchApi();
    document.getElementById('cliente').focus();
  }

  function selecaoCliente(e) {
    setAchouCliente(true);
    autoCompleteNomeClientes(e.target.value);
    setCliente(e.target.value);
  }

  useEffect(() => {
    function navegacaoTeclado(e) {
      if (e.key === 'ArrowDown') {
        setCursor((c) => (c < listaAutoComplete.length - 1 ? c + 1 : c));
      }
      if (e.key === 'ArrowUp') {
        setCursor((c) => (c > 0 ? c - 1 : 0));
      }
      if (e.key === 'Enter' && cursor > -1) {
        e.preventDefault();
        setCliente(listaAutoComplete[cursor].nome_fantasia);
        setClienteId(listaAutoComplete[cursor].id);
        setListaAutoComplete([]);
      }
      if (listRef.current && listRef.current.children && cursor >= 0 && cursor < listRef.current.children.length) {
        listRef.current.scrollTop = listRef.current.children[cursor].offsetTop;
      }
    }
    inputRef.current.addEventListener('keydown', navegacaoTeclado);

    return () => {
      if (inputRef.current != null) {
        inputRef.current.removeEventListener('keydown', navegacaoTeclado);
      }
    };
  }, [cursor, listaAutoComplete]);

  useEffect(() => {
    if (listaClientes && listaClientes.length > 0 && atualizarLista) {
      selecaoCliente({ target: { value: cliente } });
    }
  }, [listaClientes, atualizarLista]);

  return (
    <div className="mb-3 col">
      <div className="form-floating">
        <input
          onChange={selecaoCliente}
          ref={inputRef}
          value={cliente}
          type="text"
          className="form-control"
          autoComplete="off"
          id="cliente"
          name="cliente"
          placeholder="Cliente"
          onFocus={() => setFocus(true)}
          disabled={editar ? false : true}
        />
        <label htmlFor="cliente" className="obrigatorio">
          Cliente
        </label>
        {listaAutoComplete.length > 0 && focus === true ? (
          <div>
            <ul className="lista-container" ref={listRef}>
              {listaAutoComplete.map((nome, index) => (
                <li
                  className={`item-lista ${cursor === index ? 'selecionado' : null}`}
                  key={nome.id}
                  value={nome.id}
                  onClick={() => {
                    setCliente(nome.nome_fantasia.toUpperCase());
                    setClienteId(nome.id);
                    setListaAutoComplete([]);
                  }}
                >
                  <span>{nome.nome_fantasia.toUpperCase()}</span>
                  <br></br>
                  <span>{nome.razao_social.toUpperCase()}</span>
                  <br></br>
                  <span>CNPJ: {nome.cnpj}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : cliente.length > 1 && !clienteId ? (
          <div className="mt-2 ms-1">
            <span>Cliente cadastrado e não encontrado?</span>
            <button onClick={AtualizarListagemClientes} className="btn btn-link">
              Atualize
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default ProcuraCliente;

ProcuraCliente.propTypes = {
  editar: PropTypes.bool,
};
