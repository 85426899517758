import React from 'react';
import { FaChevronLeft } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Titulo({ principal, titulo, retorno, complementoTitulo, children }) {
  return (
    <div className="row justify-content-between align-items-center mb-2 mt-3">
      {principal ? (
        <>
          <div className="col-md-4 col-xl-7 mb-1">
            <a
              href="#collapseExample"
              className="btn-colapse"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <h5 className="text-dark mt-3 titulo">{titulo}</h5>
            </a>
          </div>
          {complementoTitulo ? complementoTitulo : null}
        </>
      ) : (
        <div className="row grid-linha-titulo">
          <div className="col-lg-1 col-sm-2 col-auto mt-2 ps-3">
            <Link className="btn btn-lg btn-light btn-rounded" to={`/${retorno}`}>
              <FaChevronLeft style={{ margin: '0 0 3px 0' }} size={20} />
            </Link>
          </div>
          <div className="col-lg-11 col-sm-10 col-auto ms-1 mb-1">
            {children ? (
              <a
                href="#collapseExample"
                className="btn-colapse"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="text-dark mt-3 titulo">{titulo}</h5>
              </a>
            ) : (
              <h5 className="text-dark mt-3 titulo">{titulo}</h5>
            )}
          </div>
          {complementoTitulo ? complementoTitulo : null}
        </div>
      )}
      {children ? children : null}
    </div>
  );
}

export default Titulo;

Titulo.propTypes = {
  principal: PropTypes.bool,
  titulo: PropTypes.string.isRequired,
  retorno: PropTypes.string,
  complementoTitulo: PropTypes.node,
  children: PropTypes.node,
};
