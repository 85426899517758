import { useContext } from 'react';
import { DownloadsContext } from '../../../context/downloadsContext.jsx';
import './styles.css';
import { TbProgressCheck, TbProgressDown, TbProgressX } from 'react-icons/tb';
import { MdRefresh, MdOutlineClose } from 'react-icons/md';
import { formataTamanho } from '../../../util/utils.js';

function DownloadsProgress({ open }) {
  const { downloads, cancelDownload, retryFailedDownloads } = useContext(DownloadsContext);

  const size = 20;
  const progress = {
    P: <TbProgressDown color="blue" size={size} />,
    E: <TbProgressX color="red" size={size} />,
    C: <TbProgressCheck color="green" size={size} />,
  };

  const statusTextColor = {
    P: 'text-primary d-flex flex-column',
    E: 'text-danger d-flex flex-column',
    C: 'text-success d-flex flex-column',
  };

  return (
    <div className={`downloads-area ${open ? 'downloads-area-open' : ''}`}>
      <div className="d-flex flex-column align-items-center">
        <h4>Downloads:</h4>
        <div className="mt-2 w-100">
          {downloads.map((download) => (
            <div key={download.nome} className="download-card">
              <div className="d-flex align-items-end justify-content-between">
                <strong className="fs-6">{download.nome}</strong>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger border-0 p-0 mx-2"
                    onClick={() => cancelDownload(download.id)}
                    title="Cancelar download"
                  >
                    <MdOutlineClose />
                  </button>
                  {download?.hasError ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary border-0 p-0 mx-2"
                      onClick={() => retryFailedDownloads(download.id)}
                    >
                      <MdRefresh />
                    </button>
                  ) : null}
                </div>
              </div>
              {download.arquivos?.map((arquivo) => (
                <div key={arquivo.nome} className="my-2 border rounded p-1">
                  <div key={arquivo.nome} className="d-flex align-items-center justify-content-between my-2">
                    <div className={statusTextColor[arquivo.status]}>
                      <span>{arquivo.nome}</span>
                      <span>
                        {formataTamanho(arquivo.tamanho)} / {formataTamanho(arquivo.processado)} - {arquivo.porcentagem}%
                      </span>
                    </div>
                    <div>{progress[arquivo.status]}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DownloadsProgress;
